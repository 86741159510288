import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null,
  token: localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userSignin: (state, action) => {
        const {user,token} = action.payload
      state.userInfo = user;
      state.token = token;
      localStorage.setItem("userInfo",JSON.stringify(user))
      localStorage.setItem("token",JSON.stringify(token))
    },
    userSignout: (state) => {
      state.userInfo = null;
      state.token = null;
      localStorage.clear();
    },
    
  },
});

export const { userSignin, userSignout } = userSlice.actions;

export default userSlice.reducer;
