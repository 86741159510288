import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function UnprotectedRoute({ children }) {

  const { userInfo } = useSelector((state)=>state.user);

  return userInfo && userInfo.role === "admin" ? (
    <Navigate to="/admin/dashboard" />
  ) : (
    children
  );
}
