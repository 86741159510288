import React from 'react'
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'

function PicModal({placeholder,pic,picPreview,showModal,handleModalClose, handleImgUpload,handleImageChange}) {
    
    const {isLoading} = useSelector((state)=>state.loading);

  return (
    <Modal show={showModal} onHide={handleModalClose}>
    <Modal.Header closeButton>
      <Modal.Title>Change Cover Picture</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Form className="text-center px-md-4">
      
        <Image
          src={picPreview || (pic || placeholder)}
          alt="Cover Image"
          style={{
            width: "150px",
            height: "150px",
            objectFit: "cover",
           // borderRadius: "50%",
            marginBottom: "10px",
          }}
        /><br/>
        <Form.Control
        type="file"
          id="image"
          label="Choose a new profile picture"
          accept="image/*"
          onChange={handleImageChange}
        />
      
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleModalClose}>
        Close
      </Button>

      <Button variant="primary" onClick={handleImgUpload}>
      {isLoading?
  <Spinner
  as="span"
  animation="border"
  size="sm"
  role="status"
  aria-hidden="true"
/>
:
   "Save Changes"}
        
    </Button>
    </Modal.Footer>
  </Modal>
  )
}

export default PicModal