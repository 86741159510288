import React, { useEffect, useState } from "react";
import { MotionDiv } from "../../components";
import { Button, Col, Container, FloatingLabel, Form, InputGroup, ProgressBar, Row, Spinner } from "react-bootstrap";
import { getError, toastOptions } from "../../utils/error";
import { uploadAudio, uploadImage } from "../../utils/uploadAWSfunc.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../features/loadingSlice";
import axiosInstance from "../../utils/axiosUtil";
import PicModal from "./PicModal.js";
import BrandMaterialModal from "./BrandMaterialModal.js";
import { FaGear } from 'react-icons/fa6'
import { FaExternalLinkAlt } from "react-icons/fa";
import { addOption, deleteOption } from "../../utils/apis.js";

function UploadProgressToast({ progress }) {
    return (
      <div>
        <div>Uploading...</div>
        <ProgressBar variant="success" now={progress} label={`${progress}%`} />
      </div>
    );
  }


function AddGuitar() {

    const {token} = useSelector((state)=>state.user);
    const {isLoading} = useSelector((state)=>state.loading);
    const dispatch = useDispatch();
    const navigate = useNavigate();

   const musicImg = '/images/musicImg.jpg';

  const [picToUpload, setPicToUpload]= useState(null)
  const [picPreview, setPicPreview]= useState(null)
  const [pic,setPic] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [guitarName,setGuitarName]= useState("");  
  const [affiliateLink,setAffiliateLink]= useState("");  
  // const [artist,setArtist]= useState("");  
  const [type,setType]= useState("");  
  const [brand,setBrand]= useState("");  
  const [material,setMaterial]= useState("");  

  console.log(guitarName);
  const [lowFrequencyFile, setLowFrequencyFile] = useState("");
  const [fingerStyleFile, setFingerStyleFile] = useState("");
  const [pickFile, setPickFile] = useState("");
  const [strummingFile, setStrummingFile] = useState("");
  const [chordFile, setChordFile] = useState("");

  const [style1, setStyle1] = useState("");
  const [style2, setStyle2] = useState("");
  const [style3, setStyle3] = useState("");
  const [style4, setStyle4] = useState("");
  const [style5, setStyle5] = useState("");

  const [acousticStrings, setAcousticStrings] = useState([]);
  const [electricStrings, setElectricStrings] = useState([]);
  // const [brandOptions, setBrandOptions] = useState([]);
  const { brandOptions, materialOptions } = useSelector((state) => state.options);
  console.log({brandOptions,materialOptions})
  const [showBrandMaterialModal,setShowBrandMaterialModal] = useState(false)
  const [categoryToManage, setCategoryToManage] = useState(""); // Initialize the state

  
  const handleBrandMaterialModalOpen = (category) => {
    setCategoryToManage(category);
    setShowBrandMaterialModal(true);
  };

  const handleBrandMaterialModalClose = () => {
    setShowBrandMaterialModal(false);
  };

  const handleAddOption = (category, option) => {
    dispatch(addOption({ category, option }));
  };

  const handleDeleteOption = (category, option) => {
    dispatch(deleteOption({ category, option }));
  };

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const uploadPercentageHandler = (per) => { setUploadPercentage(per); };

  const acousticInputs = [
    { label: "Bass Arpegio", name: "lowFrequency", setFile: setLowFrequencyFile, file:lowFrequencyFile },
    { label: "Fingerpicking", name: "fingerStyle", setFile: setFingerStyleFile, file:fingerStyleFile },
    { label: "Pick Arpegio", name: "pick", setFile: setPickFile, file:pickFile },
    { label: "Strumming", name: "strumming", setFile: setStrummingFile, file:strummingFile },
    { label: "Single Chord", name: "chord",setFile: setChordFile, file:chordFile },
  ];

  const electricInputs = [
    { label: "Style 1", name: "style1", setFile: setStyle1, file:style1 },
    { label: "Style 2", name: "style2", setFile: setStyle2, file:style2 },
    { label: "Style 3", name: "style3", setFile: setStyle3, file:style3 },
    { label: "Style 4", name: "style4", setFile: setStyle4, file:style4 },
    { label: "Style 5", name: "style5", setFile: setStyle5, file:style5 },
  ];

  useEffect(() => {
    setAcousticStrings([
      { name: "lowFrequency", file: lowFrequencyFile },
      { name: "fingerStyle", file: fingerStyleFile },
      { name: "pick", file: pickFile },
      { name: "strumming", file: strummingFile },
      { name: "chord", file: chordFile },
    ]);
  
    setElectricStrings([
      { name: "style1", file: style1 },
      { name: "style2", file: style2 },
      { name: "style3", file: style3 },
      { name: "style4", file: style4 },
      { name: "style5", file: style5 },
    ]);
  }, [
    lowFrequencyFile,
    fingerStyleFile,
    pickFile,
    strummingFile,
    chordFile,
    style1,
    style2,
    style3,
    style4,
    style5,
  ]);
  

  const renderInputs = (inputs) => (
    inputs.map((a, i) => (
      <Row key={i}>
        <Col>
          <Form.Group controlId={`formFile-${a.name}`} className="my-2">
            <Form.Label className="fw-bold ">{a.label} File</Form.Label>
            <Form.Control type="file" required accept="audio/*" onChange={(e) => handleFileChange(e, a.setFile, a.file)} />
          </Form.Group>
        </Col>
        <Col className="pt-4">
          {a.file && (
            <audio key={a.file} controls>
              <source src={a.file} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          )}
        </Col>
      </Row>
    ))
  );

  const handleModalOpen = (e) => {
    setShowModal(true);
  };

  const handleModalClose = (e) => {
    setShowModal(false);
  };
 
  const handleImageChange = (e)=>{

    if (e.target.files[0].size > 5000000) {
      toast.warning("Image size is too large. (max size 5MB)",toastOptions);
      setPicPreview(null);
      return;
    }

    const selectedImg = e?.target?.files[0];
   setPicToUpload(selectedImg)
   setPicPreview(URL.createObjectURL(selectedImg));
  
 }

  const handleImgUpload = async() => {

    try {
        if(!picToUpload){
            return;
        }
        try {
         
          dispatch(showLoading());
          const location = await uploadImage(
            picToUpload,
            token,
            uploadPercentageHandler
          );
          if (location.error) {
            throw location.error;
          }
    
         setPic(location);
    
          setTimeout(() => {
            setUploadPercentage(0);
           
          }, 1000);
  
          toast.success("Image Uploaded successfully",toastOptions);
  
          handleModalClose();
          dispatch(hideLoading());
        } catch (error) {
          dispatch(hideLoading());
          toast.error(error, toastOptions);
        }
  
  
    } catch (error) {
       toast.error(getError(error));
      console.log(error);
  
    }
   
  };

  const handleFileChange = async(e,setFile,file) => {

    if(!e.target.files[0]){
        toast.warning("Please select a file",toastOptions);
        return;
    }

    if(e.target.files[0].size > 11006600){
        toast.warning("File size is too large. (max size 10mb)",toastOptions);
        return;
    }    


        try {
            // toast.info(<UploadProgressToast progress={uploadPercentage} />,toastOptions);

            const location = await uploadAudio(
                e.target.files[0],
                token,
                uploadPercentageHandler
            )
            if(location.error){
                throw location.error;
            }
            toast.success("Upload successfull",toastOptions);
    
           setUploadPercentage(0); 
           console.log("In add guitar: ",location)  
         await setFile(location);  
         console.log("Selected File:", file);

        } catch (error) {
            console.log(error);
            toast.error(error,toastOptions);
        } 
    
  };

  const handleSubmit = async(e)=>{
    e.preventDefault();

    const formData ={
        guitarName,
        guitarPic: pic,
        type,
        affiliateLink,
        brand,
        material,
        strings: type === 'acoustic'?acousticStrings:type==='electric'?electricStrings:null
        
    }

    try {
        dispatch(showLoading());
        console.log("Form Data",formData)
            const response = await axiosInstance.post('/api/admin/guitar',formData,{
                headers:{
                    Authorization: token,
                }
            })
            console.log(response);
            toast.success("Guitar file added successfully",toastOptions);
            navigate('/admin/guitar');

        dispatch(hideLoading());
        
    } catch (error) {
        dispatch(hideLoading());
        toast.error(getError(error),toastOptions);
        console.log(error);
    }
  }

  return (
    <MotionDiv>
        <Container className="border p-3">
        <h3 className="mb-3">Guitar</h3>
      <Form onSubmit={handleSubmit}>
        
          <Row className="mb-3">
          <Col md={5}>
              <img src={pic || musicImg} width={'150px'} height={'150px'} style={{border:'2px solid grey',padding:'2px'}}/>
             <br/>
              <Button className="my-2" onClick={()=>{
                setPicPreview(null);
                handleModalOpen();
               } }>Upload Image</Button>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label className="fw-bold">Guitar name</Form.Label>
                <Form.Control required value={guitarName} onChange={(e)=>setGuitarName(e.target.value)}/>
              </Form.Group>
            </Col>
            <Col md={6}>

            <Form.Group>
                <Form.Label className="fw-bold">Brand</Form.Label>
                <Row>
                <Col md={10}>
                  {/* <p>BrandOptions: {brandOptions.}</p> */}
                <Form.Select required value={brand} onChange={(e)=>setBrand(e.target.value)} aria-label="brand select">
      <option>Select Brand</option>
      {brandOptions && brandOptions.map((option) => (
                <option key={option._id} value={option.name}>
                  {option.name}
                </option>
              ))}
    </Form.Select>
    </Col>
    <Col>
    <Button variant="transparent" className="" onClick={() => handleBrandMaterialModalOpen('brand')}>
              <FaGear size={22}/>
            </Button>
            </Col>
            </Row>
              </Form.Group>

              {/* <Form.Group>
                <Form.Label className="fw-bold">Artist</Form.Label>
                <Form.Control required value={artist} onChange={(e)=>setArtist(e.target.value)}/>
              </Form.Group> */}

            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
            <Form.Group>
                <Form.Label className="fw-bold">Type</Form.Label>
                <Row>
                  <Col >
                  <Form.Select required value={type} onChange={(e)=>setType(e.target.value)} aria-label="brand select">
      <option>Select Type</option>
      <option value="acoustic">Acoustic</option>
      <option value="electric">Electric</option>
      
    </Form.Select>
                  </Col>
                </Row>
                
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="fw-bold">Material</Form.Label>
                <Row>
                  <Col md={10}>
                  <Form.Select required value={material} onChange={(e)=>setMaterial(e.target.value)} aria-label="brand select">
      <option>Select Material</option>
      {materialOptions && materialOptions.map((option) => (
                <option key={option._id} value={option.name}>
                  {option.name}
                </option>
              ))}
    </Form.Select>
                  </Col>
                  <Col>
                  <Button variant="transparent" className="" onClick={() => handleBrandMaterialModalOpen('material')}>
                  <FaGear size={22}/>
            </Button>
                  </Col>
                </Row>
                
    
              </Form.Group>
            </Col>
          </Row>

          <Row>
         
          <Col md={7}>

             <Form.Group>
                <Form.Label className="fw-bold">Affiliate Link</Form.Label>
                <Row>
                  <Col md={10}>
                  <Form.Control value={affiliateLink} onChange={(e)=>setAffiliateLink(e.target.value)}/>

                  </Col>
                  {affiliateLink && <Col >
                   <Button variant="transparent" className="" href={affiliateLink} target="_blank">
                  <FaExternalLinkAlt size={22} />
                  </Button>
                  </Col>}
                </Row>
                
              
              </Form.Group>       
      
          </Col>
          </Row>

          {type === 'acoustic' && renderInputs(acousticInputs)}
          {type === 'electric' && renderInputs(electricInputs)}
              

 
          <ProgressBar
  className="my-3"
  now={uploadPercentage}
  label={`Uploading... ${uploadPercentage}%`}
  variant="success"
/>

  <Button className="mt-2 px-4" type="submit">
    {isLoading?
  <Spinner
  as="span"
  animation="border"
  size="sm"
  role="status"
  aria-hidden="true"
/>
:
   "Submit"}
   </Button>
      </Form>



      <PicModal placeholder={musicImg} pic={pic} picPreview={picPreview} showModal={showModal} handleModalClose={handleModalClose} handleImageChange={handleImageChange} handleImgUpload={handleImgUpload} />
      
      <BrandMaterialModal
        show={showBrandMaterialModal}
        onHide={handleBrandMaterialModalClose}
        categoryToManage={categoryToManage}
        options={categoryToManage === 'brand' ? brandOptions : materialOptions}
        onAddOption={handleAddOption}
        onDeleteOption={handleDeleteOption}
      />
      </Container>
    </MotionDiv>
  );
}

export default AddGuitar;
