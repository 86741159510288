import React, {  useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { MessageBox } from "../components";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { userSignout } from "../features/userSlice";

export default function AdminProtectedRoute({ children }) {
  
  const { userInfo, token } = useSelector((state)=>state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const checkToken = async () => {
      if (jwt_decode(token)?.exp < Date.now() / 1000) {
        dispatch(userSignout());
        localStorage.removeItem("userInfo");
        localStorage.removeItem("token");
        navigate("/");
      }
    };

    checkToken();
  }, [token]);

  return userInfo ? (
    userInfo.role === "admin" ? (
      children
    ) : (
      <MessageBox variant={"danger"}>Restricted</MessageBox>
    )
  ) : (
    <Navigate to="/" />
  );
}
