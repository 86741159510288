import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/error";
import { MdDelete } from "react-icons/md";
import { addOption, deleteOption } from "../../utils/apis";

const BrandMaterialModal = ({ show, onHide, categoryToManage, options }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const [newOption, setNewOption] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  console.log("in modal: ",options)
  const handleAddOption = async () => {
    if (newOption.trim() === "") {
      toast.error("Options can't be empty", toastOptions);
      return;
    }
    try {
        setIsAdding(true)
      await addOption({
        category: categoryToManage,
        option: newOption,
        token: token,
        dispatch: dispatch,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setNewOption("");
      setIsAdding(false)
    }
  };

  const handleDeleteOption = async (optionToDelete) => {
    try {
      setIsDeleting(true);
      await deleteOption({
        category: categoryToManage,
        option: optionToDelete,
        token: token,
        dispatch: dispatch,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{`Manage ${categoryToManage} Options`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Add New {categoryToManage} Option:</Form.Label>
          <Form.Control
            type="text"
            value={newOption}
            onChange={(e) => setNewOption(e.target.value)}
            placeholder={`Enter new ${categoryToManage} option`}
          />
        </Form.Group>
        <Button
          className="my-2"
          variant="primary"
          onClick={handleAddOption}
          disabled={isDeleting || isAdding}
        >
          {isAdding ? "Adding..." : "Add +"}
        </Button>
    
        <ul className="list-group mt-3">
          {options?.length > 0 ? (
            options?.map((option,i) => (
              <li
                key={option?._id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                {option?.name}
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDeleteOption(option._id)}
                  disabled={isDeleting}
                >
                 <MdDelete size={20} />
                </Button>
              </li>
            ))
          ) : (
            <li className="list-group-item text-muted text-center">
              No options available
            </li>
          )}
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export default BrandMaterialModal;
