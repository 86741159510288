import "./SideNavBar.css";
import React, { useContext, useState } from "react";
import { Store } from "../../states/store";
import { Link, useNavigate } from "react-router-dom";
import { RiDashboard2Fill } from "react-icons/ri";
import { HiUsers } from "react-icons/hi";
import {
  MdCreateNewFolder,
  MdSchema,
  MdWarehouse,
  MdOutlineContentPaste,
  MdOutlineSwapHoriz,
  MdManageAccounts,
  MdDashboard,
} from "react-icons/md";
import {
  FaEnvelopeOpenText,
  FaFileInvoiceDollar,
  FaGuitar,
  FaMailBulk,
  FaShoppingBasket,
  FaSignOutAlt,
  FaUser,
  FaUserCircle,
  FaUserPlus,
} from "react-icons/fa";
import { GrUserManager } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { userSignout } from "../../features/userSlice";

const linkList = [
  {
    icon: <MdDashboard className="icon-md" />,
    text: "Dashboard",
    url: "/admin/dashboard",
  },
  {
    icon: <FaUser className="icon-md" />,
    text: "Users",
    url: "/admin/users",
  },
  {
    icon: <FaGuitar className="icon-md" />,
    text: "Guitars",
    url: "/admin/guitar",
  },
  {
    icon: <FaEnvelopeOpenText className="icon-md" />,
    text: "Bulk Email",
    url: "/admin/bulk-email",
  },
  
];

const active_text = {
  Users:"users",
  Dashboard:"dashboard",
  Strings:"strings",
 
};

export default function SideNavbar({ isExpanded }) {
  const pathname = window.location.pathname;
  const [activeLink, setActiveLink] = useState("Dashboard");
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signoutHandler = () => {
    dispatch(userSignout());
    
    navigate("/");
  };

  const activeLinkHandler = (text) => {
    
    return pathname.includes(active_text[text]);
  };

  const cls = `nav-item has-treeview ${
    isExpanded ? "menu-item" : "menu-item menu-item-NX"
  }`;

  const logoWidth = isExpanded?"100px":"55px";

  console.log({ userInfo });
  return (
    <>
      {userInfo ? (
        <div
          className={
            isExpanded
              ? "side-nav-container"
              : "side-nav-container side-nav-container-NX"
          }
        >
          <div className="brand-link text-center" style={{backgroundColor:'rgba(26, 30, 31, 1)'}}>
            <img src="/logo/WikiLogo.png" alt="" width={logoWidth} height={"auto"} />
            <span className="brand-text ms-2 font-weight-light">
              {/* Wiki-Strings */}
            </span>
          </div>

          <div className="sidebar">
            {/* Sidebar user panel (optional) */}
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="info">
                <Link to="/admin/view-profile" className="d-block">
                  {userInfo?.avatar && (
                    <img
                      src={userInfo?.avatar}
                      alt=""
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        marginRight: "0.5rem",
                      }}
                    />
                  )}
                  <FaUserCircle className="text-white mx-2" size={"25px"} />

                  <span className="info-text">
                    Welcome {userInfo ? `${userInfo?.fullname}` : "Back"}
                  </span>
                </Link>
              </div>
            </div>
            {/* Sidebar Menu */}
            <nav className="mt-2">
              <ul
                className="nav-pills nav-sidebar px-0 d-flex flex-column flex-wrap"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                {linkList.map(({ icon, text, url }) => (
                  <li
                    key={url}
                    className={`${cls} ${
                      activeLinkHandler(text) && "active-item"
                    }`}
                    onClick={() => setActiveLink(text)}
                  >
                    <Link to={url} className="nav-link">
                      {icon}
                      <p className="ms-2">{text}</p>
                    </Link>
                  </li>
                ))}

                <li className={cls}>
                  <Link onClick={signoutHandler} to="/" className="nav-link">
                    <FaSignOutAlt className="icon-md" />
                    <p className="ms-2">Log Out</p>
                  </Link>
                </li>
              </ul>
            </nav>
            {/* /.sidebar-menu */}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
