import { createSlice } from '@reduxjs/toolkit';

const updateLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const optionsSlice = createSlice({
  name: 'options',
  initialState: {
    brandOptions: JSON.parse(localStorage.getItem('brandOptions')) || [],
    materialOptions: JSON.parse(localStorage.getItem('materialOptions')) || [],
    status: 'idle',
    error: null,
  },
  reducers: {
    addOption: (state, action) => {
      const { category, option } = action.payload;
      state[`${category}Options`] = option;
      updateLocalStorage(`${category}Options`, state[`${category}Options`]);
    },
    removeOption: (state, action) => {
      const { category, option } = action.payload;
      state[`${category}Options`] = state[`${category}Options`].filter((item) => item !== option);
      updateLocalStorage(`${category}Options`, state[`${category}Options`]);
    },
  },
});

export const { addOption, removeOption } = optionsSlice.actions;
export default optionsSlice.reducer;
