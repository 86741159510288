
import React, {  useEffect,  useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  MessageBox,
  useTitle,
  MotionDiv,
  CustomTable,
  ViewButton,
  DeleteButton,
} from "../../components";
import { getError, toastOptions } from "../../utils/error";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../features/loadingSlice";
import axiosInstance from "../../utils/axiosUtil";
import { Button, Modal } from "react-bootstrap";

export default function Guitar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state)=>state.user);
  const {isLoading} = useSelector((state)=>state.loading);  
  const [error,setError] = useState(null);
  const [guitars,setGuitars] = useState([]);
  const [guitarsCount,setGuitarsCount] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [query, setQuery] = useState("");

  const curPageHandler = (p) => setCurPage(p);

  const [showModal, setShowModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const handleOpenModal = (itemId) => {
    setDeleteItemId(itemId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setDeleteItemId(null);
  };


  const deleteGuitar = async () => {
    try {
      const response = await axiosInstance.delete(`/api/admin/guitar/${deleteItemId}`, {
        headers: {
          Authorization: token,
        },
      });
      console.log(response);
      toast.success("Guitar deleted successfully", toastOptions);
      handleCloseModal();
      fetchGuitar();
      
    } catch (error) {
      console.error(error);
      toast.error(getError(error), toastOptions);
    }
  };

  const fetchGuitar = async()=>{
console.log(query)
    let url =`/api/admin/guitar/?keyword=${query}&&resultPerPage=${resultPerPage}&currentPage=${curPage}`;
    try {
        dispatch(showLoading());
         const response = await axiosInstance.get(url,{
            headers:{
                Authorization: token
            }
         });
         console.log(response);
         const {guitars,guitarsCount} = response.data;   
         setGuitars(guitars);
         setGuitarsCount(guitarsCount);
        dispatch(hideLoading());

    } catch (error) {
       setError(getError(error));
        dispatch(hideLoading());
    }
}

useEffect(()=>{

    fetchGuitar();

},[token,resultPerPage,curPage,query]);


  const numOfPages = Math.ceil(guitarsCount / resultPerPage);
  const skip = resultPerPage * (curPage - 1);
  console.log("no of Page", numOfPages, resultPerPage,guitarsCount);

  const column = [
     "S.No",
     "Guitars",
     "Brand",
     "Material",
     "Type",
     "Actions"
   
  ];

  useTitle("Guitars");
  return (
    <MotionDiv>
      {error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : ( 
        <CustomTable
          loading={isLoading}
          column={column}
          rowNo={resultPerPage}
          rowProps={{ setResultPerPage }}
          paging={numOfPages > 0}
          pageProps={{ numOfPages, curPage }}
          pageHandler={curPageHandler}
          title="Guitars"
          isCreateBtn = {true}
          createBtnProps={{ createURL: "/admin/add-guitar", text: "New Guitar" }}
          search={true}
          searchProps={{ searchInput, setSearchInput, setQuery }}
          isTitle="true"
          
         
        >
          {guitars &&
            guitars.map((guitar, i) => (
              <tr key={guitar?._id} className="odd">
                <td className="text-center">{skip + i + 1}</td>
                {/* <td>{string?.updatedAt?.slice(0, 10)}</td> */}
                <td>{guitar?.guitarName}</td>
                {/* <td>{guitar?.artist}</td> */}
                <td>{guitar?.brand}</td>
                <td>{guitar?.material}</td>
                <td>{guitar?.type.toUpperCase()}</td>
                {/* <td>{user?.fullname}</td>
                <td>{user?.mobile}</td> */}
                {/* <td>{user.role}</td> */}
                <td>
                  <ViewButton
                    onClick={() => navigate(`/admin/guitar/${guitar?._id}`)}
                  />
                  <DeleteButton 
                  onClick={() => handleOpenModal(guitar?._id)}
                   />
                </td> 
              </tr>
            ))}
        </CustomTable>
      )}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this guitar?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteGuitar}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </MotionDiv>
  );
}
