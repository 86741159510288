import { useDispatch } from "react-redux";
import { addOption as addOptionAction, removeOption as deleteOptionAction } from "../features/optionsSlice";
import axiosInstance from "./axiosUtil";

export const addOption = async ({ category, option, token,dispatch }) => {
  try {
    console.log({ category, option, token,dispatch });
    const response = await axiosInstance.post(`/api/admin/${category}`, { name: option }, {
      headers: {
        Authorization: token,
      },
    });
    console.log(response);
     
    getOptions({dispatch:dispatch,token:token});

   // dispatch(addOptionAction({ category, option: response.data }));

    return response.data;
  } catch (error) {
    throw Error('Failed to add option to the API');
  }
};

export const deleteOption = async ({ category, option, token,dispatch }) => {

    console.log({ category, option, token,dispatch });

  try {
    const response = await axiosInstance.delete(`/api/admin/${category}/${option}`, {
      headers: {
        Authorization: token,
      },
    });
    console.log(response);

    getOptions({dispatch:dispatch,token:token});

   // dispatch(deleteOptionAction({ category, option }));

    return response.data;
  } catch (error) {
    throw Error('Failed to delete option from the API');
  }
};

export const getOptions = async ({ dispatch, token }) => {
  try {
    const response = await axiosInstance.get('/api/admin/options', {
      headers: {
        Authorization: token,
      },
    });

    console.log(response);

    const { brands, materials } = response?.data;

    dispatch(addOptionAction({ category: 'brand', option: brands }));
    dispatch(addOptionAction({ category: 'material', option: materials }));

    return { brands, materials };
  } catch (error) {
    console.log(error);
  }
};
