import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Store } from "../states/store";
import { reducer } from "../states/reducers";
import { FaEnvelope, FaLock } from "react-icons/fa";
import { useTitle } from "../components";
import { getError, toastOptions } from "../utils/error";
import { clearErrors, login } from "../states/actions";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../features/loadingSlice";
import axiosInstance from "../utils/axiosUtil";
import { userSignin } from "../features/userSlice";

export default function AdminLoginScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { token } = useSelector((state)=>state.user);
  const {isLoading} = useSelector((state)=>state.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const handleLogin = async(e)=>{
    e.preventDefault();
    try {
      dispatch(showLoading());
      const response = await axiosInstance.post('/api/user-login',{
        email:email,
        password:password,
      });
      console.log(response);
      if(response.status === 200 ){
        const {user,token} = response.data;
        dispatch(userSignin({user,token}));
         navigate("/admin/dashboard");
      }
      
     
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      getError(error)
      toast.error(error, toastOptions);
    }
  }

  // useEffect(() => {
  //   if (token) {
  //     setTimeout(() => {
  //       navigate("/admin/dashboard");
  //     }, 2000);
  //   }
  //   if (error) {
  //     toast.error(error, toastOptions);
  //     clearErrors(dispatch);
  //   }
  // }, [error, token]);

  useTitle("Login");
  return (
    <Container fluid className="p-0 vh-100 f-center flex-column login-page">
      <div className="login-logo">
        <Link to="/" className="text-center">
          <b>Wiki-Strings Admin</b>
        </Link>
      </div>

      <Card className="login-box">
        <Card.Body>
          <p className="text-center">Sign in to start your session</p>
          <Form onSubmit={handleLogin}>
            <Form.Group controlId="text" className="input-group mb-3">
              <Form.Control
                placeholder="Email/Mobile"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <InputGroup.Text>
                <FaEnvelope />
              </InputGroup.Text>
            </Form.Group>
            <Form.Group controlId="password" className="input-group mb-3">
              <Form.Control
                placeholder="Password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <InputGroup.Text>
                <FaLock />
              </InputGroup.Text>
            </Form.Group>
            <Row>
              <Col sm={7} className="mb-sm-0 mb-3">
                <Form.Group controlId="remember">
                  <Form.Check
                    type="checkbox"
                    id="default-checkbox"
                    label="Remember Me"
                  />
                </Form.Group>
              </Col>
              <Col sm={5}>
                {isLoading ? (
                  <Button type="submit" className="float-sm-end" disabled>
                    <Spinner animation="border" size="sm" />
                  </Button>
                ) : (
                  <Button type="submit" className="float-sm-end">
                    Sign In
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
          <ToastContainer />
        </Card.Body>
      </Card>
    </Container>
  );
}
