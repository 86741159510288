import React, { useState } from 'react';
import { Card, Container, Form, Button, Spinner } from 'react-bootstrap';
import { getError } from '../../utils/error';
import { toast } from 'react-toastify';
import axiosInstance from '../../utils/axiosUtil';
import { useSelector } from 'react-redux';

function BulkEmail() {
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const {token} = useSelector((state)=>state.user);
  const [loading,setLoading] = useState(false);
  const handleSubmit = async(e) => {
    e.preventDefault();
   
    // console.log('Submitting', { subject, description });

    try {
        setLoading(true);
        const {data} = await axiosInstance.post('/api/admin/send-bulk-email',{
            subject,
            description
        },{
            headers:{
                Authorization: token,
            }
        })
        toast.success(data?.message)
        setSubject('');
        setDescription('')
        setLoading(false)

    } catch (error) {
        setLoading(false)
        console.log(error);
       toast.error(getError(error));
    }
  };

  return (
    <Container className='pt-3'>
      <h3>News Letter</h3>
      <Card className='shadow'>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formEmailSubject">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmailBody">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter email body"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit">
             {loading?
               <Spinner /> 
            : 'Send Email'
             }
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default BulkEmail;
