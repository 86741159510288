import React, { useEffect, useState } from "react";
import { MotionDiv } from "../../components";
import { Button, Col, Container, FloatingLabel, Form, ProgressBar, Row, Spinner } from "react-bootstrap";
import { getError, toastOptions } from "../../utils/error";
import { uploadAudio, uploadImage } from "../../utils/uploadAWSfunc";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { hideLoading, showLoading } from "../../features/loadingSlice";
import axiosInstance from "../../utils/axiosUtil";
import PicModal from "./PicModal";
import { FaExternalLinkAlt } from "react-icons/fa";

function UploadProgressToast({ progress }) {
  return (
    <div>
      <div>Uploading...</div>
      <ProgressBar now={progress} label={`${progress}%`} />
    </div>
  );
}

function ViewGuitar() {

    const {token} = useSelector((state)=>state.user);
    const {isLoading} = useSelector((state)=>state.loading);

  const { id } = useParams();
  const guitarId = id;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const musicImg = '/images/musicImg.jpg';
  const { brandOptions, materialOptions } = useSelector((state) => state.options);


  const [disabled,setDisabled] = useState(true);
  const [picToUpload, setPicToUpload]= useState(null)
  const [picPreview, setPicPreview]= useState(null)
  const [showModal, setShowModal] = useState(false);

  const [guitarName, setGuitarName] = useState("");
  const [pic, setPic] = useState(null);
  // const [artist, setArtist] = useState("");
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");
  const [material, setMaterial] = useState("");
  const [affiliateLink, setAffiliateLink] = useState("");

  const [firstAudio, setFirstAudio] = useState("");
  const [secondAudio, setSecondAudio] = useState("");
  const [thirdAudio, setThirdAudio] = useState("");
  const [fourthAudio, setFourthAudio] = useState("");
  const [fifthAudio, setFifthAudio] = useState("");


  const [uploadPercentage, setUploadPercentage] = useState(0);

  const uploadPercentageHandler = (per) => {
    setUploadPercentage(per);
  };

  const handleModalOpen = (e) => {
    setShowModal(true);
  };

  const handleModalClose = (e) => {
    setShowModal(false);
  };

  
  const renderInputs = (inputs) => {
    return (
      <>
        {inputs.map((a, i) => (
          <Row key={i} className="mt-3">
            <Form.Label className="fw-bold ">{a.label} File</Form.Label>
            {!disabled && (
              <Col>
                <Form.Group className="mt-2">
                  <Form.Control type="file" disabled={disabled} accept="audio/*" onChange={(e) => handleFileChange(e, a.setAudio, a.audio)} />
                </Form.Group>
              </Col>
            )}
  
            <Col className="">
              {a.audio && (
                <audio key={a.audio} controls>
                  <source src={a.audio} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
            </Col>
          </Row>
        ))}
      </>
    );
  };
  

 

  const acousticData = [
    { label: "Bass Arpegio", name: "lowFrequency", audio: firstAudio, setAudio: setFirstAudio, },
    { label: "Fingerpicking", name: "fingerStyle", audio: secondAudio, setAudio: setSecondAudio, },
    { label: "Pick Arpegio", name: "pick", audio: thirdAudio, setAudio: setThirdAudio },
    { label: "Strumming", name: "strumming", audio: fourthAudio, setAudio: setFourthAudio },
    { label: "Single Chord", name: "chord", audio: fifthAudio, setAudio: setFifthAudio },
  ];

  const electricData = [
    { label: "Style 1", name: "style1", audio: firstAudio, setAudio: setFirstAudio, },
    { label: "Style 2", name: "style2", audio: secondAudio, setAudio: setSecondAudio, },
    { label: "Style 3", name: "style3", audio: thirdAudio, setAudio: setThirdAudio },
    { label: "Style 4", name: "style4", audio: fourthAudio, setAudio: setFourthAudio },
    { label: "Style 5", name: "style5", audio: fifthAudio, setAudio: setFifthAudio },
  ];

 
  const getGuitar = async () => {

    try {
      dispatch(showLoading());

      const response = await axiosInstance.get(`/api/admin/guitar/${guitarId}`, {
        headers: {
          Authorization: token,
        },
      });

      dispatch(hideLoading());
      console.log(response);
      const {guitar} = response?.data
      setGuitarName(guitar?.guitarName);
      setPic(guitar?.guitarPic);
     // setArtist(guitar?.artist);
      setBrand(guitar?.brand);
      setType(guitar?.type);
      setMaterial(guitar?.material);
      setAffiliateLink(guitar?.affiliateLink);


      const {strings} = guitar;

      setFirstAudio(strings[0].file);
      setSecondAudio(strings[1].file);
      setThirdAudio(strings[2].file);
      setFourthAudio(strings[3].file);
      setFifthAudio(strings[4].file);



    } catch (error) {
      dispatch(hideLoading());
      toast.error(getError(error), toastOptions);
    }
  };

  const handleImageChange = (e)=>{

    if (e.target.files[0].size > 5000000) {
      toast.warning("Image size is too large. (max size 5MB)",toastOptions);
      setPicPreview(null);
      return;
    }

    const selectedImg = e?.target?.files[0];
   setPicToUpload(selectedImg)
   setPicPreview(URL.createObjectURL(selectedImg));
  
 }

  const handleImgUpload = async() => {

    try {
        if(!picToUpload){
            return;
        }
        try {
         
          dispatch(showLoading());
          const location = await uploadImage(
            picToUpload,
            token,
            uploadPercentageHandler
          );
          if (location.error) {
            throw location.error;
          }
    
         setPic(location);
    
          setTimeout(() => {
            setUploadPercentage(0);
           
          }, 1000);
  
          toast.success("Image Uploaded successfully",toastOptions);
  
          handleModalClose();
          dispatch(hideLoading());
        } catch (error) {
          dispatch(hideLoading());
          toast.error(error, toastOptions);
        }
  
  
    } catch (error) {
       toast.error(getError(error));
      console.log(error);
  
    }
   
  };

  const handleFileChange = async(e,setFile,file) => {

    if(!e.target.files[0]){
        toast.warning("Please select a file",toastOptions);
        return;
    }

    if(e.target.files[0].size > 11006600){
        toast.warning("File size is too large. (max size 10mb)",toastOptions);
        return;
    }    


        try {
          //  toast.info(<UploadProgressToast progress={uploadPercentage} />,toastOptions);

            const location = await uploadAudio(
                e.target.files[0],
                token,
                uploadPercentageHandler
            )
            if(location.error){
                throw location.error;
            }
            toast.success("Upload successfull",toastOptions);
    
           setUploadPercentage(0); 
           console.log("In add guitar: ",location)  
         await setFile(location);  
         console.log("Selected File:", file);

        } catch (error) {
            console.log(error);
            toast.error(error,toastOptions);
        } 
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
  
    const dataToMap = type === 'acoustic' ? acousticData :type === 'electric' ? electricData:null;

    const strings = dataToMap.map((data, index) => ({
      name: data.name,
      file: data.audio,
    }));

    const formData = {
      guitarName,
      guitarPic:pic,
      type,
      brand,
      material,
      affiliateLink,
      strings
    };

    try {
      dispatch(showLoading());
      console.log("Form Data: ",formData);

       const response = await axiosInstance.put(`/api/admin/guitar/${guitarId}`, formData, {
          headers: {
              Authorization: token,
          },
      });
      dispatch(hideLoading());
      console.log(response);
      toast.success("Guitar file updated successfully", toastOptions);
      navigate('/admin/guitar');

   
    } catch (error) {
      dispatch(hideLoading());
      toast.error(getError(error), toastOptions);
    }
  };

  useEffect(() => {
    getGuitar();
  }, [token]);



  return (
    <MotionDiv>
      <Container className="border p-3">
        <Row>
          <Col>
            <h3 className="mb-3">Guitar</h3>
          </Col>
          <Col className="text-end">
            {!disabled && (
              <Button className="me-3" onClick={() => { setDisabled(true); getGuitar(); }}>
                Cancel
              </Button>
            )}
            <Button onClick={() => setDisabled(false)}>Edit</Button>
          </Col>
        </Row>

        <Form onSubmit={handleSubmit}>
          
        <Row className="mb-3">
            <Col md={5}>
              <img src={pic || musicImg} width={'150px'} height={'180px'} style={{border:'2px solid grey',padding:'2px'}}/>
             <br/>
              <Button className="my-2" disabled={disabled} onClick={()=>{
                setPicPreview(null);
                handleModalOpen();
               } }>Edit Image</Button>
            </Col>
            
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label className="fw-bold">Guitar name</Form.Label>
                <Form.Control required disabled={disabled} value={guitarName} onChange={(e)=>setGuitarName(e.target.value)}/>
              </Form.Group>
            </Col>
            <Col md={6}>

            <Form.Group>
                <Form.Label className="fw-bold">Brand</Form.Label>
                <Form.Select required disabled={disabled} value={brand} onChange={(e)=>setBrand(e.target.value)} aria-label="brand select">
      <option>Select Brand</option>
      {brandOptions && brandOptions.map((option) => (
                <option key={option._id} value={option?.name}>
                  {option?.name}
                </option>
              ))}
    </Form.Select>
              </Form.Group>

              {/* <Form.Group>
                <Form.Label className="fw-bold">Artist</Form.Label>
                <Form.Control required disabled={disabled} value={artist} onChange={(e)=>setArtist(e.target.value)}/>
              </Form.Group> */}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
            <Form.Group>
                <Form.Label className="fw-bold">Type</Form.Label>
                <Form.Select required disabled={disabled} value={type} onChange={(e)=>setType(e.target.value)} aria-label="brand select">
      <option>Select Type</option>
      <option value="acoustic">Acoustic</option>
      <option value="electric">Electric</option>
      
    </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="fw-bold">Material</Form.Label>
                <Form.Select required disabled={disabled} value={material} onChange={(e)=>setMaterial(e.target.value)} aria-label="brand select">
      <option>Select Material</option>
      {materialOptions && materialOptions.map((option) => (
                <option key={option?._id} value={option?.name}>
                  {option?.name}
                </option>
              ))}
    </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
          
          <Col md={6}>

          <Form.Group>
                <Form.Label className="fw-bold">Affiliate Link</Form.Label>
                <Row>
                  <Col>
                  <Form.Control disabled={disabled} value={affiliateLink} onChange={(e)=>setAffiliateLink(e.target.value)}/>
                  </Col>
                  {affiliateLink && <Col md={2}>
                   <Button  variant="transparent" className="" href={affiliateLink} target="_blank">
                  <FaExternalLinkAlt size={22} />
                  </Button>
                  </Col>}
                </Row>              
              </Form.Group>       
      
          </Col>
          </Row>

          {/* {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} label={`${uploadPercentage}%`} />} */}

          {type === 'acoustic' && renderInputs(acousticData)}
          {type === 'electric' && renderInputs(electricData)}          


          <ProgressBar
  className="my-2"
  now={uploadPercentage}
  label={`Uploading... ${uploadPercentage}%`}
  variant="success"
/>

          <Button className="mt-1 px-4" disabled={disabled} type="submit">
            {isLoading ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              "Update"
            )}
          </Button>
        </Form>

        <PicModal placeholder={musicImg} pic={pic} picPreview={picPreview} showModal={showModal} handleModalClose={handleModalClose} handleImageChange={handleImageChange} handleImgUpload={handleImgUpload} />

      </Container>
    </MotionDiv>
  );
}

export default ViewGuitar;
