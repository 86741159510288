import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AdminProtectedRoute, UnprotectedRoute } from "./routes";
import { Header, Footer, SideNavBar, NotFound } from "./components";
import { AdminLoginScreen, Dashboard, } from "./pages";
import { useDispatch, useSelector } from "react-redux";
import Users from "./pages/users/Users";
import ViewProfile from "./pages/ViewProfile";
import Guitar from "./pages/guitar/Guitar";
import AddGuitar from "./pages/guitar/AddGuitar";
import ViewGuitar from "./pages/guitar/ViewGuitar";
import {  getOptions } from "./utils/apis";
import BulkEmail from "./pages/bulkEmail/BulkEmail";

function App() {
  const { token } = useSelector((state)=>state.user);
  const dispatch = useDispatch();
  console.log(token)
  const pageLocation = useLocation();

  const [isExpanded, setExpandState] = useState(window.innerWidth > 768);
  const sidebarHandler = () => setExpandState((prev) => !prev);

  useEffect( async()=>{

   await getOptions({dispatch:dispatch ,token:token});

  },[token])

  const routeList = [
    { path: "/admin/dashboard", comp: <Dashboard /> },
    { path: "/admin/users", comp: <Users /> },
    { path: "/admin/guitar", comp: <Guitar /> },
    { path: "/admin/guitar/:id", comp: <ViewGuitar /> },
    { path: "/admin/add-guitar", comp: <AddGuitar /> },
    { path: "/admin/bulk-email", comp: <BulkEmail /> },
    { path: "/admin/view-profile", comp: <ViewProfile /> },
    
    // add you path and component here
  ];

  return (
    <div className="main-wrapper">
      {isExpanded && token && (
        <div className="sidebar-overlay" onClick={sidebarHandler}></div>
      )}
      <div className="sidebar-wrapper">
        <SideNavBar isExpanded={isExpanded} />
      </div>
      <div
        className={`body-wrapper ${isExpanded ? "mini-body" : "full-body"} 
        ${token ? "" : "m-0"} d-flex flex-column`}
      >
        <Header sidebarHandler={sidebarHandler} />
        <Routes location={pageLocation} key={pageLocation.pathname}>
          <Route
            path="/"
            element={
              <UnprotectedRoute>
                <AdminLoginScreen />
              </UnprotectedRoute>
            }
          />

          {routeList.map(({ path, comp }) => (
            <Route
              key={path}
              path={path}
              element={<AdminProtectedRoute>{comp}</AdminProtectedRoute>}
            />
          ))}

          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
