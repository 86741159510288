
import React, {  useEffect,  useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  MessageBox,
  useTitle,
  MotionDiv,
  CustomTable,
  ViewButton,
  DeleteButton,
} from "../../components";
import { getError, toastOptions } from "../../utils/error";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../features/loadingSlice";
import axiosInstance from "../../utils/axiosUtil";

export default function Users() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state)=>state.user);
  const {isLoading} = useSelector((state)=>state.loading);  
  const [error,setError] = useState(null);
  const [users,setUsers] = useState([]);
  const [usersCount,setUsersCount] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [query, setQuery] = useState("");

  const curPageHandler = (p) => setCurPage(p);



useEffect(()=>{
    
    const fetchUsers = async()=>{

        let url =`/api/admin/users/?resultPerPage=${resultPerPage}&currentPage=${curPage}`;
        try {
            dispatch(showLoading());
             const response = await axiosInstance.get(url,{
                headers:{
                    Authorization: token
                }
             });
             console.log(response);
             const {users,usersCount} = response.data;   
             setUsers(users);
             setUsersCount(usersCount);
            dispatch(hideLoading());

        } catch (error) {
           setError(getError(error));
            dispatch(hideLoading());
        }
    }

    fetchUsers();

},[token,resultPerPage,curPage]);
  const numOfPages = Math.ceil(usersCount / resultPerPage);
  const skip = resultPerPage * (curPage - 1);
  console.log("no of Page", numOfPages, resultPerPage,usersCount);

  const column = [
    "S.No",
    "Date",
    "Email",
    "Fullname",
    "Mobile No.",
    // "Role",
    // "Actions"
  ];

  // useTitle("Users");
  useTitle("Users");
  return (
    <MotionDiv>
      {error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : ( 
        <CustomTable
          loading={isLoading}
          column={column}
          rowNo={resultPerPage}
          rowProps={{ setResultPerPage }}
          paging={numOfPages > 0}
          pageProps={{ numOfPages, curPage }}
          pageHandler={curPageHandler}
        //    search={true}
        //    searchProps={{ searchInput, setSearchInput, setQuery }}
          isTitle="true"
          
          title="Users"
        >
          {users &&
            users.map((user, i) => (
              <tr key={user?._id} className="odd">
                <td className="text-center">{skip + i + 1}</td>
                <td>{user?.updatedAt?.slice(0, 10)}</td>
                <td>{user?.email}</td>
                <td>{user?.fullname}</td>
                <td>{user?.mobile}</td>
                {/* <td>{user.role}</td>
                <td>
                  <ViewButton
                    onClick={() => navigate(`/admin/view/user/${user._id}`)}
                  />
                  <DeleteButton onClick={() => deleteUser(user._id)} />
                </td> */}
              </tr>
            ))}
        </CustomTable>
      )}
      <ToastContainer />
    </MotionDiv>
  );
}
